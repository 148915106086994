/*************** Login page ********/
body {
    font-family: inter;
    background-color: #F5F5F5;
}
.bg-dark {
    background-color: #0C1116 !important;
}
.bg-white {
    background-color: #FFFFFF !important;
}
.wrapper {
    align-items: stretch;
    display: flex;
    width: 100%;
}
.btn-primary, .btn-primary.disabled, .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn.disabled {
    background-color: #28D893 !important;
    border-color: #28D893 !important;
    border-radius: 8px;
    padding: 4px !important;
}

.form-signin {
    max-width: 400px;
}

.form-control, .form-control:focus {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000 !important;
}

#login-section .heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
}
#login-section label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #999999;
}
#login-section .form-control{
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #ECECEC;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}
#login-section .btn-login {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    height: 44px;;
}
#login-section .password-view {
    position: absolute;
    top: 53%;
    right: 4%;
    cursor: pointer;
    width: 24px;
    height: 24px;
}
.navbar-vertical-container {
    position: relative;
    height: 100%;
    min-height: 100%;
}
.navbar.navbar-vertical {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    align-items: center;
}
.navbar-brand  {
    text-align: center;
}
.navbar-vertical-content {
    height: calc(100% - 3.875rem);
    overflow-y: auto;
}
.navbar-vertical-content {
    overflow: hidden;
}
.navbar-vertical-aside .nav.nav-pills {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}
.nav-vertical.nav-pills {
    width: 100%;
}
.nav-vertical {
    flex-direction: column;
}
.card-navbar-nav {
    width: 100%;
    padding: 1.5rem 0rem;
}
.nav-link {
    color: #F0F0F0;
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}
.sidebar .nav-link.active,  .sidebar .nav-link:hover{
    color: #FFFFFF;
    font-weight: 600px;
    background-color: #242D35;
    border-radius: 0px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #242D35;
    border-radius: 0px;
}
.navbar.navbar-vertical {
    padding: 0px;
    padding-top:1rem;
}
.sidebar .nav-link .nav-link-icon {
    flex-shrink: 0;
    margin-right: 0.625rem;
    width: 24px;
}
.sidebar.shrink-sidebar {
    max-width: 72px;
    min-width: 72px;
}
.sidebar.shrink-sidebar .nav-link .nav-title, .sidebar.shrink-sidebar .main-logo, .sidebar.shrink-sidebar .logout-btn .btn-title{
    display: none;
}
.sidebar.shrink-sidebar .mini-logo {
    display: block !important;
}
.sidebar.shrink-sidebar .sidebar-content{
    text-align: center;
}
.sidebar.shrink-sidebar .navbar-aside-toggler {
    left: 78%;
}
.nav-vertical .nav-item{
    margin-bottom: 10px;
}
.navbar-vertical .navbar-aside-toggler {
    position: absolute;
    top: 0.734375rem;
    right: -1.203125rem;
}
.navbar-aside-toggler {
    opacity: 1;
    z-index: 1000;
    width: 24px;
    height: 24px;
    background-image: url("/images/icons/ic_expand_more.png");
    background-repeat: no-repeat;
    border-radius: 50%;
    background-position: center;
    position: absolute;
    left: 94%;
    top: 8%;
    border: 1px solid #D5D5D5;
}
.navbar-nav-wrap {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    align-items: center;
}
.navbar.navbar-container .navbar-nav-wrap-content-start {
    padding-left: 0;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.sidebar {
    background: #293042;
    direction: ltr;
    max-width: 260px;
    min-width: 260px;
    transition: margin-left .35s ease-in-out,left .35s ease-in-out,margin-right .35s ease-in-out,right .35s ease-in-out;
}
.sidebar-content {
    height: 100vh;
    left: 0;
    top: 0;
}
.sidebar-brand {
    display: block;
    font-size: 1.125rem;
    font-weight: 500;
    padding: 1.15rem 1.5rem;
    text-align: center;
}
@media (min-width: 1px) and (max-width: 991.98px) {
    .sidebar {
        margin-left: -260px;
    }
}
.main {
    display: flex;
    width: 100%;
    min-height: 100vh;
    min-width: 0;
    flex-direction: column;
}
.main .navbar {
    padding: 16px 24px;
    border-bottom: 1px solid #E5E5E5;
}
.main .navbar-toggler {
    margin-right: 10px;
    font-size: 1rem;
}
.main .navbar-toggler::selection {
    border: none;
}
.input-group-navbar {
    min-width: 240px;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.input-group-navbar .form-control{
    height: calc(2.24375rem + 2px);
    background: #FFFFFF;
    box-shadow: none;
    border: 0;
    padding: 0.35rem 0.75rem;
    border-radius: 8px;
}
.input-group-navbar .btn  {
    border: 1px solid #ECECEC;
    border-left: 0;
}
.input-group-navbar .form-control {
    border: 1px solid #ECECEC;
    border-right: 0px;
}
.form-control::placeholder {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #999999 !important;
}
.navbar .user-title{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.navbar .user-avatar {
    width: 24px;
    height: 24px;
    border: 2px solid #D5F4E8;
    margin-left: 10px;
}
.navbar-align {
    margin-left: auto;
}
.page-title {
    position: absolute;
    left: 48%;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.empty-box .heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}
.width-400 {
    width: 400px;
}
#dashboard-section .box-description {
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
}
@media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg {
        border-right: 1px solid #0C1116;
        bottom: 0;
        display: block;
        max-width: 264px;
        /* overflow-y: auto; */
        /* position: fixed; */
        top: 0;
        width: 100%;
        z-index: 1000;
    }
    .navbar-vertical.navbar-expand-lg>[class*=container] {
        align-items: stretch;
        flex-direction: column;
        /* min-height: 100%; */
        padding-left: 0;
        padding-right: 0;
    }
}
@media screen and (max-width: 576px){
    .d-sm-inline-block {
        display: inline-block!important;
    }
    .navbar-expand .navbar-toggler {
        display: block;
    }
}
@media (max-width: 767.98px){
    .main {
        overflow-y: hidden;
    }
}
@media (min-width: 1px) and (max-width: 991.98px) {
    .sidebar.collapsed {
        margin-left: 0;
    }
}
.navbar-brand .main-logo {
    width: 158px;
}
.navbar-brand .mini-logo {
    width: 20px;
    height: 20px;
}
.danger-button {
    background: #DB302A;
    border-radius: 8px;
    border: 1px solid #DB302A;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color:#FFFFFF;
}
.sidebar .logout-btn {
    width: 134px;
    height: 44px;
}
.sidebar.shrink-sidebar .logout-btn {
    width: 52px;
    height: 40px;
}
.sidebar.shrink-sidebar .nav-link .nav-link-icon {
    margin-left: 6px;
}
/*************** page content css ***********/
.content .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
}
#setting-content label {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #0C1116;
}
#setting-content .password-view {
    position: absolute;
    top: auto;
    bottom: 10px;
    right: 10px;
    max-width: 20px;
    cursor: pointer;
}
.form-error {
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #F03738;
    margin-top: 6px;
}
.btn-submit {
    width: 165px;
    height: 44px;
}
.btn-add {
    width: 118px;
    height: 34px;
}
#dashboard-section .form-control {
    height: 36px;
}
.date-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #242D35;
}
.table-light {
    --bs-table-bg: #F3FFFA;
    --bs-table-border-color: #E5E5E5;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #242D35;
}
table.rounded-table {
    border-radius: 8px;
    border: 1px solid #ECECEC;
}
table.rounded-table .date-time {
    font-size: 10px;
    line-height: 16px;
}
table.rounded-table td {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}
table .status-mark {
    width: 18px;
    height: 18px;
}
.primary-text {
    color: #4781D2;
}
.table .action-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.react-datepicker-wrapper input {
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    height: 36px;
    width: 135px;
    padding: 10px;
    background-image: url(/images/icons/ic-calendar.png);
    background-repeat: no-repeat;
    background-position: right 10px center;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #242D35;
}
.date-picker-box {
    width: 135px;
}
.icon-calendar {
    width: 18px;
    height: 18px;
    position: absolute;
}
.form-select {
    border: 1px solid #ECECEC;
    border-radius: 8px;
    width: 178px;
    height: 36px;
    --bs-form-select-bg-img: url(/images/icons/ic-more-down.png);
    background-size: 20px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
.btn {
    padding: 0;
}
.sub-heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    font-style: normal;
}
.sub-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
}
.sub-text .add-more-icon {
    width: 13px;
    height: 13px;
    margin-right: 6px;
}
textarea.form-control {
    height: 120px !important;
}
.btn-default, .btn-default:hover {
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    color: #000;
    font-weight: 600;
    line-height: 20px;
    font-size: 16px;
}
#create-form .btn-submit {
    padding: 10px 32px;
    font-size: 16px;
    font-family: Inter;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}
.news-sidebar .heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
}
.news-box {
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 8px;
}
.news-box .heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #1C1939;
}
.news-box .icon-start {
    width: 16px;
    height: 16px;
}
.news-box .date-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    color: #9FA3A9;
}
.news-box .league-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #3D83F3;
}
.news-box .news-content {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #1C1939
}
.news-box .social-link {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #A56BF9;
}
.news-box .like-count {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #5F5D70;
}
@media (max-width:767px){
    .toast-notification{
        width: 90% !important;
        span{
            display: block !important;
        }
    }
}
.form-control{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;    
    color: #000;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus, .form-select:focus {   
    border: 1px solid #ECECEC;
    box-shadow: none;
    outline: 0 none;
}

.form-control:disabled {
    background-color: #eee;
}
.back-icon {
    width: 23px;
    height: 23px;
}
.react-form-select {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    border: 1px solid #ECECEC !important;
    border-radius: 8px !important;
    width: 180px;
    min-height: 36px !important;
}
.react-select__option {
    font-size: 14px;
}
th div.sort-enable {
    background-image: url(/images/icons/ic_up_down.png);
    background-repeat: no-repeat;
    /* background-position: 65px center; */
    cursor: pointer;
}
.btn-confirm, .btn-confirm:hover, .btn-confirm:focus {
    padding: 12px 32px !important;
    text-align: center;
    font-size: 16px;
    font-family: Inter;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
}
.model-heading {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-family: Inter;
    font-weight: 700;
    line-height: 22px;
}
.modal-message {
    color: #999;
    text-align: center;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 22px;
}
.modal-dialog .btn {
    text-align: center;
    font-size: 16px;
    font-family: Inter;
    font-weight: 600;
    line-height: 20px;
}
.btn.btn-default.disabled, .btn.btn-default:disabled {
    border-color: #D5D5D5;
}
.cmn-loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000020;
    z-index: 100000000;
}

.cmn-loader img{
    width: 45px;
}
.reset-link {
    font-size: 14px;
    color: #4781D2;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}
.form-label {
    margin-bottom: 10px;
}
.modal-message {
    color: #999;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.modal-message img {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}
.modal-dialog .btn.btn-primary {
    font-size: 12px !important;
    padding: 4px 0px !important;
}
.modal {
    --bs-modal-width: 600px;
}
/*Change text in autofill textbox*/
input:-webkit-autofill{
    -webkit-text-fill-color: #000 !important;
}